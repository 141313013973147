import { AppHelper } from '../AppHelper';

const scoreCodesV2_helper = [
	{ c:'GM', c2: 'grammar', n:'Grammar', v:0, p:0, eg: [], sub: [], expand: false }, 
	{ c:'SP', c2: 'spelling', n:'Spelling', v:0, p:0, eg: [], sub: [], expand: false },
	{ c:'VC', c2: 'vocabulary', n:'Vocabulary', v:0, p:0, eg: [], sub: [], expand: false }, 
	{ c:'ST', c2: 'structure_and_flow', n:'Structure and Flow', v:0, p:0, eg: [], sub: [], expand: false }
];
const scoreCodesV4_helper = [
	{ c:'CR', c2: 'conent_relevance', n:'Relevance & Logic', v:0, p:0, eg: [], sub: [], expand: false },
	{ c:'CT', c2: 'content', n:'Development', v:0, p:0, eg: [], sub: [], expand: false },
	{ c:'GM', c2: 'grammar', n:'Grammar', v:0, p:0, eg: [], sub: [], expand: false }, 
	{ c:'SP', c2: 'spelling', n:'Spelling', v:0, p:0, eg: [], sub: [], expand: false },
	{ c:'VC', c2: 'vocabulary', n:'Vocabulary', v:0, p:0, eg: [], sub: [], expand: false }, 
	{ c:'ST', c2: 'structure_and_flow', n:'Structure and Flow', v:0, p:0, eg: [], sub: [], expand: false },
];

export const EssayHelper = {
	async essayGradeGet(token){
		const grade = [];
		try {
		  const response = await AppHelper.apiPost('api/grade/list', {token: token});
		  if (response && response.status && response.data) {
				response.data.forEach((x)=>
				{
					grade.push({
						id:x.gradeId,
						v:x.code,
						n:x.name,
						minW: x.maxWord ? x.maxWord : 100
					});
				});
		  } else {
				console.error('Failed to fetch grades:', response);
		  }
		} catch (error) {
		  	console.error('Error fetching grades:', error);
		}
		return grade;
	},
	essayInfoGetForPopScore(_res, _rulesJson){
		var _rec = {
      essayId: _res.data.essayId, profileId: _res.data.profileId, topic:_res.data.topic, grade:_res.data.grade, 
      text: _res.text, //_res.correctionResult ? _res.correctionResult : 'NA',
      scores: [...scoreCodesV2_helper],
      email:_res.email,
	    student:_res.student,
      suggestions: [],//[...scoreResult.suggestions],
      avgScoreP: 0,
      imgs:[],
			score:_res.score
    };
		//console.log('essayInfoGet', _res, _rulesJson, _rec);
		var _scTotal = 0;
		_rec.scores.forEach(_sc=>{
			//_sc.eg = [];
			var _arrScRec = _res.score.filter(_rs=>_rs.scoreCode == _sc.c);
			const _scSum = _arrScRec.reduce((total, current) => total + current.score, 0);
			_sc.v = _arrScRec.length > 0 ? (_scSum/_arrScRec.length) : 0;
			_sc.v = parseFloat(_sc.v.toFixed(1));
			
			var _rule = _rulesJson.find(r=>r.code == _sc.c);
			
			if(_sc.c == 'GM'){
				if(_rule && _rule.data && _arrScRec && _arrScRec.length>0){
					var _errPercent = getErrorPercentByScore(_arrScRec[0].score);
					var _tmpScoreSP = 1;
					var _isScoreSPSet = false;
					_rule.data.forEach((x, iX)=>{
						if(!_isScoreSPSet && _errPercent<x.v){
							_tmpScoreSP = 10-iX;
							_isScoreSPSet = true;
						}
					});
					_sc.v = _tmpScoreSP;
					//console.log('SP', _tmpScoreSP, _errPercent, _arrScRec, _rule.data);
				}
			}
			else if(_sc.c == 'SP'){
				if(_rule && _rule.data && _arrScRec && _arrScRec.length>0){
					var _errPercent = getErrorPercentByScore(_arrScRec[0].score);
					var _tmpScoreSP = 1;
					var _isScoreSPSet = false;
					_rule.data.forEach((x, iX)=>{
						if(!_isScoreSPSet && _errPercent<x.v){
							_tmpScoreSP = 10-iX;
							_isScoreSPSet = true;
						}
					});
					_sc.v = _tmpScoreSP;
					//console.log('SP', _tmpScoreSP, _errPercent, _arrScRec, _rule.data);
				}
			}
			else if(_sc.c == 'VC'){
				var _vVCA = 5;
				var _vVCX = 5;
				if(_rule && _rule.data){
					_vVCA = getScoreL2('VCA', _arrScRec, _rule, 50, 5);
					_vVCX = getScoreL2('VCX', _arrScRec, _rule, 50, 5);
				}
				_sc.v = getFloat((_vVCA+_vVCX)/(1*2));
				//console.log('rule-VCA', _vVCA, 'rule-VCX', _vVCX, 'v', _sc.v);
			}
			// if(_sc.c == 'VC' && _arrScRec.length==1){
			// 	_sc.v = getFloat(_sc.v/2);
			// }
			else if(_sc.c == 'ST'){
				//_sc.v = getFloat(_sc.v * 2.5);
				var _stORG = 2.5, _stTRA = 2.5, _stPAR = 2.5, _stTIT = 2.5;
				if(_rule && _rule.data){
					_stORG = getScoreL2('O', _arrScRec, _rule, 25, 2.5, 2.5);
					_stTRA = getScoreL2('T', _arrScRec, _rule, 25, 2.5, 2.5);
					_stPAR = getScoreL2('P', _arrScRec, _rule, 25, 2.5, 2.5);
					_stTIT = getScoreL2('TR', _arrScRec, _rule, 25, 2.5, 2.5);
				}
				_sc.v = getFloat((_stORG + _stTRA + _stPAR + _stTIT)/(1*4));
				//console.log('_ST', _sc.c2, _stORG, _stTRA, _stPAR, _stTIT, _sc.v, _arrScRec);
			}

			_sc.v_r = _sc.v;
			if(_rule){
				_sc.v_r = getFloat(_sc.v * (_rule.v/25));
			}
			_scTotal += _sc.v_r;
			//console.log(_sc.c, _sc.v, _sc.v_r, _rule.v);
		});
		_rec.avgScoreP_r = (_scTotal / (10*4))*100;
		if(_res.scoreResultCT)
			_rec.avgScoreP_r = (_scTotal / (10*6))*100;
		
		_rec.avgScoreP_r = Math.round(_rec.avgScoreP_r);
		//console.log('essayInfoGet', _rec, _scTotal);
		//console.log('essayInfoGet', _rec.scores, _scTotal);
		return _rec;
	},
	async essayInfoGetV2(token, _res, _rulesJson, _apiScore){
		console.log('essayInfoGet V2---', _res);
		if(!_apiScore){
			var _resApiScore = await EssayHelper.essayScoreGet(token, _res.data.profileId, _res.data.essayId);
			console.log('apiScore', _resApiScore);
			if(!_resApiScore || _resApiScore.length<1){
				//alert('Failed to load score from API');
				//return;
			}
		}
		var _rec = {
      essayId: _res.data.essayId, profileId: _res.data.profileId, topic:_res.data.topic, grade:_res.data.grade, 
      text: _res.text, //_res.correctionResult ? _res.correctionResult : 'NA',
      scores: _res.score[0].version=='4.00' ? [...scoreCodesV4_helper]: [...scoreCodesV2_helper],
      email:_res.email,
	    student:_res.student,
      suggestions: [],//[...scoreResult.suggestions],
      avgScoreP: 0,
      imgs:[],
			score:_res.score
    };
		//console.log('essayInfoGet', _res, _rulesJson, _rec);
		var _scTotal = 0;
		_rec.scores.forEach(_sc=>{
			if(!_sc.eg)_sc.eg = [];
			var _arrScRec = _res.score.filter(_rs=>_rs.scoreCode == _sc.c);
			const _scSum = _arrScRec.reduce((total, current) => total + current.score, 0);
			_sc.v = _arrScRec.length > 0 ? (_scSum/_arrScRec.length) : 0;
			_sc.v = parseFloat(_sc.v.toFixed(1));
			
			var _rule = _rulesJson.find(r=>r.code == _sc.c);
			
			if(_sc.c == 'GM'){
				if(_rule && _rule.data && _arrScRec && _arrScRec.length>0){
					var _errPercent = getErrorPercentByScore(_arrScRec[0].score);
					//console.log('GM-_errPercent', _errPercent);
					var _tmpScoreSP = 1;
					var _isScoreSPSet = false;
					_rule.data.forEach((x, iX)=>{
						if(!_isScoreSPSet && _errPercent<=x.v){
							_tmpScoreSP = 10-iX;
							_isScoreSPSet = true;
						}
					});
					_sc.v = _tmpScoreSP;
					//console.log('SP', _tmpScoreSP, _errPercent, _arrScRec, _rule.data);
				}
			}
			else if(_sc.c == 'SP'){
				if(_rule && _rule.data && _arrScRec && _arrScRec.length>0){
					var _errPercent = getErrorPercentByScore(_arrScRec[0].score);
					var _tmpScoreSP = 1;
					var _isScoreSPSet = false;
					_rule.data.forEach((x, iX)=>{
						if(!_isScoreSPSet && _errPercent<=x.v){
							_tmpScoreSP = 10-iX;
							_isScoreSPSet = true;
						}
					});
					_sc.v = _tmpScoreSP;
					//console.log('SP', _tmpScoreSP, _errPercent, _arrScRec, _rule.data);
				}
			}
			else if(_sc.c == 'VC'){
				var _vVCA = 5;
				var _vVCX = 5;
				if(_rule && _rule.data){
					//_vVCA = getScoreL2('VCA', _arrScRec, _rule, 50, 5);
					_vVCA = getScore_byRulePercent('VCA', _arrScRec, _rule, 10, 5);
					//console.log('VCA', _vVCA, _arrScRec, _rule);
					//_vVCX = getScoreL2('VCX', _arrScRec, _rule, 50, 5);
					_vVCX = getScore_byRulePercent('VCX', _arrScRec, _rule, 10, 5);
					//console.log('VCX', _vVCX);
				}
				//console.log('VC', getFloat(_vVCA+_vVCX));
				_sc.v = getFloat(_vVCA+_vVCX);
				//_sc.v = getFloat((_vVCA+_vVCX)/(1*2));
				//console.log('rule-VCA', _vVCA, 'rule-VCX', _vVCX, 'v', _sc.v);
			}
			// if(_sc.c == 'VC' && _arrScRec.length==1){
			// 	_sc.v = getFloat(_sc.v/2);
			// }
			else if(_sc.c == 'ST'){
				//_sc.v = getFloat(_sc.v * 2.5);
				var _stORG = 2.5, _stTRA = 2.5, _stPAR = 2.5, _stTIT = 2.5;
				if(_rule && _rule.data){
					_stORG = getScoreL2('O', _arrScRec, _rule, 25, 2.5, 2.5);
					_stTRA = getScoreL2('T', _arrScRec, _rule, 25, 2.5, 2.5);
					_stPAR = getScoreL2('P', _arrScRec, _rule, 25, 2.5, 2.5);
					_stTIT = getScoreL2('TR', _arrScRec, _rule, 25, 2.5, 2.5);
				}
				else{
					console.log('^^^^^ no rule.data', _rulesJson);
				}
				_sc.v = getFloat((_stORG + _stTRA + _stPAR + _stTIT)/(1*4));
				//console.log('_ST', _sc.c2, _stORG, _stTRA, _stPAR, _stTIT, _sc.v, _arrScRec);
			}
			else if(_sc.c == 'CT'){
				var _ctL = 2.5, _ctI = 3.33, _ctB = 3.33, _ctC = 3.33;
				if(_rule && _rule.data){
					//_ctL = getScoreL2('L', _arrScRec, _rule, 25, 2.5, 2.5);
					_ctI = getScoreL2('I', _arrScRec, _rule, 33.33, 3.33, 3.33);
					_ctB = getScoreL2('B', _arrScRec, _rule, 33.33, 3.33, 3.33);
					_ctC = getScoreL2('C', _arrScRec, _rule, 33.33, 3.33, 3.33);
				}
				else{
					console.log('^^^^^ no rule.data', _rulesJson);
				}
				_sc.v = getFloat((_ctI + _ctB + _ctC)/(1*6));
				//console.log('_CT', _sc.c2, _ctI, _ctB, _ctC, _sc.v, _arrScRec);
			}
			else if(_sc.c == 'CR'){
				var _crL = 30, _crR = 30;
				if(_rule && _rule.data){
					_crL = getScoreL2('L', _arrScRec, _rule, 50, 30, 5);
					_crR = getScoreL2('R', _arrScRec, _rule, 50, 30, 5);
				}
				else{
					console.log('^^^^^ no rule.data', _rulesJson);
				}
				_sc.v = getFloat((_crL + _crR)/(1*6));
				//console.log('_CR', _sc.c2, 'L', _crL, 'R', _crR, _sc.v, _arrScRec);
			}

			_sc.v_r = _sc.v;
			if(_rule){
				_sc.v_r = getFloat(_sc.v * (_rule.v/25));
			}
			_scTotal += _sc.v_r;
			//console.log(_sc.c, _sc.v, _sc.v_r, _rule.v);
		});
		//console.log('overall check', _res);
		_rec.avgScoreP_r = (_scTotal / (10*4))*100;
		if(_res.scoreResultCT || _res.score[0].version == '4.00')
			_rec.avgScoreP_r = (_scTotal / (10*6))*100;
		_rec.avgScoreP_r = Math.round(_rec.avgScoreP_r);
		//console.log('essayInfoGet', _rec, _scTotal);
		//console.log('essayInfoGet', _rec.scores, _scTotal);
		return _rec;
	},
	async essayDetailScore(token, essayId, _isTEA=false){
		var _res = await AppHelper.apiPost('api/essay/detail', {token: token, essayId: essayId});
		console.log('essay detail ->',_res);
		if(!_res || !_res.status || !_res.data) return null;
		var _profileId = _res.data.profileId;
		var _resScore = await AppHelper.apiPost('api/scores', {token:token, profileId:_profileId, essayId:essayId});
		console.log('_resScore ->',_resScore);
		if(!_resScore || _resScore.length<1) return null;
		var _apiScore = _resScore[0];
		var _recScores = _res.score[0].version=='4.00' ? [...scoreCodesV4_helper]: [...scoreCodesV2_helper];
		_recScores.forEach(x=>{
			x.v = 0; x.p = 0;
			x.eg = [];
			x.sub = [];
			//x.expand = false;
		});
		var _rec = {
      essayId: _res.data.essayId, profileId: _res.data.profileId, topic:_res.data.topic, grade:_res.data.grade, 
      text: _res.text, //_res.correctionResult ? _res.correctionResult : 'NA',
      scores: _recScores,// _res.score[0].version=='4.00' ? [...scoreCodesV4_helper]: [...scoreCodesV2_helper],
      email:_res.email,
	    student:_res.student,
      suggestions: [],//[...scoreResult.suggestions],
      avgScoreP: 0,
      imgs:_res.imgs,
			score:_res.score,
			version: parseFloat(_res.data.vEval),
			correctionResult: _res.correctionResult,
			scoreResultGM: _res.scoreResultGM,
			scoreResultSP: _res.scoreResultSP,
			scoreResultVC: _res.scoreResultVC,
			scoreResultST: _res.scoreResultST,
			
    };

		console.log('essayDetailScore', _rec.scores);


		_rec.scores.forEach(rs=>{
			if(!rs.eg) rs.eg = [];
			var _arrScRec = _res.score.filter(_rs=>_rs.scoreCode == rs.c);
			var _rsCode = `scoreResult${rs.c}`;
			if(_res[_rsCode]){
				var _jsonSR = JSON.parse(_res[_rsCode]);
				if(rs.c == 'SP'){
					var _spErrCount = _jsonSR.errors ? _jsonSR.errors.filter(x=>x.isFalse!=1).length : 0;
					rs.scoreInfo = {
						show: _spErrCount>0,
						title: `You made ${_spErrCount} spelling mistake${_spErrCount>1?'s':''} in your essay.`,
						subTitles: [],
						levelItems0: [],
						levelItems1: [],
						levelItems2: []
					};
					if(_jsonSR.levels){
						if(_jsonSR.errors){
							_jsonSR.errors.forEach((je,iJE)=>{
								je.eIndex = iJE;
							});
						}
						var _spErrLevelTotal = 0;
						var _spErrLevelDifficult = 0;
						var _spErrLevelMedium = 0;
						var _spErrLevelEasy = 0;
						if(_jsonSR.levels.difficult_words){
							_spErrLevelDifficult = _jsonSR.levels.difficult_words.length;
							_spErrLevelTotal += _jsonSR.levels.difficult_words.length;
						}
						if(_jsonSR.levels.medium_words){
							_spErrLevelMedium = _jsonSR.levels.medium_words.length;
							_spErrLevelTotal += _jsonSR.levels.medium_words.length;
						}
						if(_jsonSR.levels.easy_words){
							_spErrLevelEasy = _jsonSR.levels.easy_words.length;
							_spErrLevelTotal += _jsonSR.levels.easy_words.length;
						}

						if(_spErrLevelEasy>0){
							var _percent = (_spErrLevelEasy / _spErrLevelTotal) * 100;
							rs.scoreInfo.subTitles.push(`Easy Words: ${_percent.toFixed(2)}% of mistakes`);
						}
						if(_spErrLevelMedium>0){
							var _percent = (_spErrLevelMedium / _spErrLevelTotal) * 100;
							rs.scoreInfo.subTitles.push(`Medium Words: ${_percent.toFixed(2)}% of mistakes`);
						}
						if(_spErrLevelDifficult>0){
							var _percent = (_spErrLevelDifficult / _spErrLevelTotal) * 100;
							rs.scoreInfo.subTitles.push(`Difficult Words: ${_percent.toFixed(2)}% of mistakes`);
						}

						if(_jsonSR.levels.easy_words){
							_jsonSR.levels.easy_words.forEach(l=>{
								var _arrWord = _jsonSR.errors.filter(errorRec=> (_isTEA || errorRec.isFalse!=1) && errorRec.right_spelling == l.word);
								if(_arrWord.length<1)
									_arrWord = _jsonSR.errors.filter(errorRec=> (_isTEA || errorRec.isFalse!=1) && errorRec.right_spelling.includes(l.word));
								if(_arrWord.length>0 && !rs.scoreInfo.levelItems0.find(r=>r.w == _arrWord[0].wrong_spelling)){
									rs.scoreInfo.levelItems0.push({w: _arrWord[0].wrong_spelling, l:'Easy', r:_arrWord[0].right_spelling, f:_arrWord[0].flag, isFalse: _arrWord[0].isFalse, eIndex: _arrWord[0].eIndex});
									rs.eg.push({});
								}
							});
						}
						if(_jsonSR.levels.medium_words){
							_jsonSR.levels.medium_words.forEach(l=>{
								var _arrWord = _jsonSR.errors.filter(errorRec=> (_isTEA || errorRec.isFalse!=1)  && errorRec.right_spelling == l.word);
								if(_arrWord.length<1)
									_arrWord = _jsonSR.errors.filter(errorRec=>errorRec.right_spelling.includes(l.word));
								if(_arrWord.length>0 && !rs.scoreInfo.levelItems0.find(r=>r.w == _arrWord[0].wrong_spelling)
									&& !rs.scoreInfo.levelItems1.find(r=>r.w == _arrWord[0].wrong_spelling)){
									rs.scoreInfo.levelItems1.push({w: _arrWord[0].wrong_spelling, l:'Easy', r:_arrWord[0].right_spelling, f:_arrWord[0].flag, isFalse: _arrWord[0].isFalse, eIndex: _arrWord[0].eIndex});
									rs.eg.push({});
								}
							});
						}
						if(_jsonSR.levels.difficult_words){
							_jsonSR.levels.difficult_words.forEach(l=>{
								var _arrWord = _jsonSR.errors.filter(errorRec=> errorRec.isFalse!=1 && errorRec.right_spelling == l.word);
								if(_arrWord.length<1)
									_arrWord = _jsonSR.errors.filter(errorRec=>errorRec.right_spelling && errorRec.right_spelling.includes(l.word));
								if(_arrWord.length>0 && !rs.scoreInfo.levelItems0.find(r=>r.w == _arrWord[0].wrong_spelling)
									&& !rs.scoreInfo.levelItems1.find(r=>r.w == _arrWord[0].wrong_spelling)
									&& !rs.scoreInfo.levelItems2.find(r=>r.w == _arrWord[0].wrong_spelling)){
									rs.scoreInfo.levelItems2.push({w: _arrWord[0].wrong_spelling, l:'Easy', r:_arrWord[0].right_spelling, f:_arrWord[0].flag, isFalse: _arrWord[0].isFalse, eIndex: _arrWord[0].eIndex});
									rs.eg.push({});
								}
							});
						}
					}
				}
				else if(rs.c == 'GM'){
					var _gmErrCount = _jsonSR.errors ? _jsonSR.errors.filter(x=>x.isFalse!=1).length : 0;
					rs.scoreInfo = {
						show: _gmErrCount>0,
						title: `You made ${_gmErrCount} grammar mistake${_gmErrCount>1?'s':''} in your essay.`,
						subTitles: [],
						errors: _jsonSR.errors ? [..._jsonSR.errors] : [],
						suggestions: _jsonSR.suggestions ? [..._jsonSR.suggestions] : [],
						levelItems1: []
					};
					rs.scoreInfo.errors.forEach(r=>{
						rs.eg.push({eg_gm:'eg_gm'});
						var _arrSug = rs.scoreInfo.suggestions.filter(sg=>sg.text == r.w && sg.issues && sg.issues.length>0 && sg.issues[0].type != 'NA');
						if(_arrSug.length>0){
							r.type = titleAsReadable(_arrSug[0].issues[0].type, '-');
							r.subType = titleAsReadable(_arrSug[0].issues[0].subType, '-');
							r.r = _arrSug[0].issues[0].corrected_sentence;
						}
					});
					rs.scoreInfo.errors = rs.scoreInfo.errors.filter(r=>r.type != 'None');
				}
				else if(rs.c == 'VC'){
					var _scVCX = _arrScRec.find(rVCX=>rVCX.subScoreCode=='VCX');
					var _scVCA = _arrScRec.find(rVCX=>rVCX.subScoreCode=='VCA');
					var _isVCALL = _scVCX == null || _scVCA == null;
					var _vcErrCount = _jsonSR.errors ? _jsonSR.errors.length : 0;
					var _vScoreOrigin = 0;//_arrScRec.length > 0 ? (_scSum/_arrScRec.length) : 0;
					rs.scoreInfo = {
						show: _vcErrCount>0,
						title: `You made ${_vcErrCount} vocabulary mistake${_vcErrCount>1?'s':''} in your essay.`,
						subTitles: [],
						errors: _jsonSR.errors ? [..._jsonSR.errors] : [],
						suggestions: _jsonSR.suggestions ? [..._jsonSR.suggestions] : [],
						levelItems1: [],
						rareWords: [],
						blocks: [
							{
								title: `${_vScoreOrigin}/10 Vocabulary Appropriateness`,
								subTitles: [`You made ${_vcErrCount} vocabulary mistake${_vcErrCount>1?'s':''} in your essay.`] 
							},
							{title: `0/10 Vocabulary Complexity`, subTitles: [`0 uncommon words used.`, `0 rare words used.`]}
						]
					};
					rs.scoreInfo.errors.forEach(r=>{
						rs.eg.push({});
					});
					var _scoreVCA = 10;
					if(rs.scoreInfo.errors.length<1){
						rs.scoreInfo.blocks[0].hide = true;
					}
					var _vcxWordPoints = 0;
					var _block1SubTitles = [];
					rs.scoreInfo.vcxPoint = 0;

					if(_res.scoreResultVCX){
						var _jsonVCX = JSON.parse(_res.scoreResultVCX);
						if(_jsonVCX && _jsonVCX.res){
						 
							if(_jsonVCX.res.uk_uncommon_words){
								rs.scoreInfo.blocks[1].subTitles[0] = `${_jsonVCX.res.uk_uncommon_words.filter(r=>r.isFalse!=1).length} uncommon words used.`;
								_jsonVCX.res.uk_uncommon_words.forEach((wc,iWC)=>{
									rs.scoreInfo.rareWords.push({w: wc.original_word, t:'U', iWord:iWC, isFalse:wc.isFalse});
								})
								_vcxWordPoints += _jsonVCX.res.uk_uncommon_words.length * 0.5;
							}
							
							if(_jsonVCX.res.uk_rare_words){
								rs.scoreInfo.blocks[1].subTitles[1] = `${_jsonVCX.res.uk_rare_words.filter(r=>r.isFalse!=1).length} rare words used.`;
								_jsonVCX.res.uk_rare_words.forEach((wc, iWC)=>{
									rs.scoreInfo.rareWords.push({w: wc.original_word, t:'R', iWord:iWC, isFalse:wc.isFalse});
								})
								_vcxWordPoints += _jsonVCX.res.uk_rare_words.length * 1;
							}                  
						}
					}

					if(_isVCALL){
						if(rs.scoreInfo.errors.length>0){
							var _wordsCount = wordCount(_rec.text);
							var _errorWordCountPercent = Math.ceil((rs.scoreInfo.errors.length / _wordsCount)*100);
							if(_errorWordCountPercent>=9) _scoreVCA = 1;
							else if(_errorWordCountPercent>=8) _scoreVCA = 2;
							else if(_errorWordCountPercent>=7) _scoreVCA = 3;
							else if(_errorWordCountPercent>=6) _scoreVCA = 4;
							else if(_errorWordCountPercent>=5) _scoreVCA = 5;
							else if(_errorWordCountPercent>=4) _scoreVCA = 6;
							else if(_errorWordCountPercent>=3) _scoreVCA = 7;
							else if(_errorWordCountPercent>=2) _scoreVCA = 8;
							else if(_errorWordCountPercent>=1) _scoreVCA = 9;
						}

						if(_vcxWordPoints>0){  
							var _vcxPoint = 1;
							if(_vcxWordPoints>=5) _vcxPoint = 10;
							else if(_vcxWordPoints>=4.5) _vcxPoint = 9;
							else if(_vcxWordPoints>=4) _vcxPoint = 8;
							else if(_vcxWordPoints>=3.5) _vcxPoint = 7;
							else if(_vcxWordPoints>=3) _vcxPoint = 6;
							else if(_vcxWordPoints>=2.5) _vcxPoint = 5;
							else if(_vcxWordPoints>=2) _vcxPoint = 4;
							else if(_vcxWordPoints>=1.5) _vcxPoint = 3;
							else if(_vcxWordPoints>=1) _vcxPoint = 2;
							
							rs.v = (_scoreVCA + (_vcxPoint)) / 2;
							rs.p = (rs.v/10)*100;
							rs.scoreInfo.vcxPoint = _vcxPoint;
						}
					}
					else{ // VCA & VCX
						_scoreVCA = _scVCA.score;
						rs.scoreInfo.vcxPoint = _scVCX.score;
					}
					rs.scoreInfo.blocks[0].title = `${_scoreVCA}/10 Vocabulary Appropriateness`;
					rs.scoreInfo.blocks[1].title = `${rs.scoreInfo.vcxPoint}/10 Vocabulary Complexity`;
					rs.v = (_scoreVCA + (rs.scoreInfo.vcxPoint)) / 2;
					rs.p = (rs.v/10)*100;
					if(rs.eg.length<1 && rs.scoreInfo.vcxPoint>1){
						rs.eg.push({});
					}
				}
				else if(rs.c == 'CR'){
					for(let _field in _jsonSR.scores){
						var _subTitle = _field.toLowerCase().replace(rs.c2 + '_', '');
						var _arrSubTitle = _subTitle.split('_');
						_subTitle = _arrSubTitle.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
						rs.sub.push({ title: _subTitle, ..._jsonSR.scores[_field] });
						_jsonSR.scores[_field].exampleImprovements.forEach(_rEg=>{
							rs.eg.push(_rEg);
						});
					}
					if(_apiScore){
						rs.p = _apiScore.CR_score;
						rs.v = (_apiScore.CR_score / 10).toFixed(1);
					}
				}
				else if(rs.c == 'CT'){
					for(let _field in _jsonSR.scores){
						var _subTitle = _field.toLowerCase().replace(rs.c2 + '_', '');
						var _arrSubTitle = _subTitle.split('_');
						_subTitle = _arrSubTitle.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
						if(_field!='logic'){
							rs.sub.push({ title: _subTitle, ..._jsonSR.scores[_field] });
							_jsonSR.scores[_field].exampleImprovements.forEach(_rEg=>{
								rs.eg.push(_rEg);
							});
						}
						else{
							var _recOtherCR = _rec.scores.find(x=>x.c == 'CR');
							if(_recOtherCR){
								_recOtherCR.sub.push({ title: _subTitle, ..._jsonSR.scores[_field] });
								_jsonSR.scores[_field].exampleImprovements.forEach(_rEg=>{
									_recOtherCR.eg.push(_rEg);
								});
							}
							//_scoreCT_L = { title: _subTitle, ..._jsonSR.scores[_field] };
						}
					}
					if(_apiScore){
						rs.p = _apiScore.CT_score;
						rs.v = (_apiScore.CT_score / 10).toFixed(1);
					}
				}
				else{
					for(let _field in _jsonSR.scores){
						var _subTitle = _field.toLowerCase().replace(rs.c2 + '_', '');
						var _arrSubTitle = _subTitle.split('_');
						_subTitle = _arrSubTitle.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
						rs.sub.push({ title: _subTitle, ..._jsonSR.scores[_field] });
						_jsonSR.scores[_field].exampleImprovements.forEach(_rEg=>{
							rs.eg.push(_rEg);
						});
					}
				}
			}
			if(rs.c == 'CR'){
				rs.p = _apiScore.CR_score;
				rs.v = (_apiScore.CR_score/10);
			}
			else if(rs.c == 'CT'){
				rs.p = _apiScore.CT_score;
				rs.v = (_apiScore.CT_score/10);
			}
			else if(rs.c == 'GM'){
				rs.p = _apiScore.GM_score;
				rs.v = (_apiScore.GM_score/10);
			}
			else if(rs.c == 'SP'){
				rs.p = _apiScore.SP_score;
				rs.v = (_apiScore.SP_score/10);
			}
			else if(rs.c == 'VC'){
				rs.p = _apiScore.VC_score;
				rs.v = (_apiScore.VC_score/10);
			}
			else if(rs.c == 'ST'){
				rs.p = _apiScore.ST_score;
				rs.v = (_apiScore.ST_score/10);
			}
			if(rs.v%1!=0) rs.v = parseFloat(rs.v.toFixed(1));
		});

		_rec.avgScoreP = _apiScore.overall_score;
		_rec.avgScoreP_r = _apiScore.overall_score;

		return _rec;
	},
	essayInfoGet(_res, _rulesJson){
		console.log('essayInfoGet',_res);
		//if(!_res.score) return
		var _rec = {
      essayId: _res.data.essayId, profileId: _res.data.profileId, topic:_res.data.topic, grade:_res.data.grade, 
      text: _res.text, //_res.correctionResult ? _res.correctionResult : 'NA',
      scores: (!_res.score || _res.score.length<1)? [...scoreCodesV2_helper] : _res.score[0].version=='4.00' ? [...scoreCodesV4_helper]: [...scoreCodesV2_helper],
      email:_res.email,
	    student:_res.student,
      suggestions: [],//[...scoreResult.suggestions],
      avgScoreP: 0,
      imgs:[],
			score:_res.score
    };
		//console.log('essayInfoGet', _res, _rulesJson, _rec);
		var _scTotal = 0;
		_rec.scores.forEach(_sc=>{
			if(!_sc.eg)_sc.eg = [];
			var _arrScRec = _res.score.filter(_rs=>_rs.scoreCode == _sc.c);
			const _scSum = _arrScRec.reduce((total, current) => total + current.score, 0);
			_sc.v = _arrScRec.length > 0 ? (_scSum/_arrScRec.length) : 0;
			_sc.v = parseFloat(_sc.v.toFixed(1));
			
			var _rule = _rulesJson.find(r=>r.code == _sc.c);
			
			if(_sc.c == 'GM'){
				if(_rule && _rule.data && _arrScRec && _arrScRec.length>0){
					var _errPercent = getErrorPercentByScore(_arrScRec[0].score);
					//console.log('GM-_errPercent', _errPercent);
					var _tmpScoreSP = 1;
					var _isScoreSPSet = false;
					_rule.data.forEach((x, iX)=>{
						if(!_isScoreSPSet && _errPercent<=x.v){
							_tmpScoreSP = 10-iX;
							_isScoreSPSet = true;
						}
					});
					_sc.v = _tmpScoreSP;
					//console.log('SP', _tmpScoreSP, _errPercent, _arrScRec, _rule.data);
				}
			}
			else if(_sc.c == 'SP'){
				if(_rule && _rule.data && _arrScRec && _arrScRec.length>0){
					var _errPercent = getErrorPercentByScore(_arrScRec[0].score);
					var _tmpScoreSP = 1;
					var _isScoreSPSet = false;
					_rule.data.forEach((x, iX)=>{
						if(!_isScoreSPSet && _errPercent<=x.v){
							_tmpScoreSP = 10-iX;
							_isScoreSPSet = true;
						}
					});
					_sc.v = _tmpScoreSP;
					//console.log('SP', _tmpScoreSP, _errPercent, _arrScRec, _rule.data);
				}
			}
			else if(_sc.c == 'VC'){
				var _vVCA = 5;
				var _vVCX = 5;
				if(_rule && _rule.data){
					//_vVCA = getScoreL2('VCA', _arrScRec, _rule, 50, 5);
					_vVCA = getScore_byRulePercent('VCA', _arrScRec, _rule, 10, 5);
					//console.log('VCA', _vVCA, _arrScRec, _rule);
					//_vVCX = getScoreL2('VCX', _arrScRec, _rule, 50, 5);
					_vVCX = getScore_byRulePercent('VCX', _arrScRec, _rule, 10, 5);
					//console.log('VCX', _vVCX);
				}
				//console.log('VC', getFloat(_vVCA+_vVCX));
				_sc.v = getFloat(_vVCA+_vVCX);
				//_sc.v = getFloat((_vVCA+_vVCX)/(1*2));
				//console.log('rule-VCA', _vVCA, 'rule-VCX', _vVCX, 'v', _sc.v);
			}
			// if(_sc.c == 'VC' && _arrScRec.length==1){
			// 	_sc.v = getFloat(_sc.v/2);
			// }
			else if(_sc.c == 'ST'){
				//_sc.v = getFloat(_sc.v * 2.5);
				var _stORG = 2.5, _stTRA = 2.5, _stPAR = 2.5, _stTIT = 2.5;
				if(_rule && _rule.data){
					_stORG = getScoreL2('O', _arrScRec, _rule, 25, 2.5, 2.5);
					_stTRA = getScoreL2('T', _arrScRec, _rule, 25, 2.5, 2.5);
					_stPAR = getScoreL2('P', _arrScRec, _rule, 25, 2.5, 2.5);
					_stTIT = getScoreL2('TR', _arrScRec, _rule, 25, 2.5, 2.5);
				}
				else{
					console.log('^^^^^ no rule.data', _rulesJson);
				}
				_sc.v = getFloat((_stORG + _stTRA + _stPAR + _stTIT)/(1*4));
				//console.log('_ST', _sc.c2, _stORG, _stTRA, _stPAR, _stTIT, _sc.v, _arrScRec);
			}
			else if(_sc.c == 'CT'){
				var _ctL = 2.5, _ctI = 3.33, _ctB = 3.33, _ctC = 3.33;
				if(_rule && _rule.data){
					//_ctL = getScoreL2('L', _arrScRec, _rule, 25, 2.5, 2.5);
					_ctI = getScoreL2('I', _arrScRec, _rule, 33.33, 3.33, 3.33);
					_ctB = getScoreL2('B', _arrScRec, _rule, 33.33, 3.33, 3.33);
					_ctC = getScoreL2('C', _arrScRec, _rule, 33.33, 3.33, 3.33);
				}
				else{
					console.log('^^^^^ no rule.data', _rulesJson);
				}
				_sc.v = getFloat((_ctI + _ctB + _ctC)/(1*6));
				//console.log('_CT', _sc.c2, _ctI, _ctB, _ctC, _sc.v, _arrScRec);
			}
			else if(_sc.c == 'CR'){
				var _crL = 30, _crR = 30;
				if(_rule && _rule.data){
					_crL = getScoreL2('L', _arrScRec, _rule, 50, 30, 5);
					_crR = getScoreL2('R', _arrScRec, _rule, 50, 30, 5);
				}
				else{
					console.log('^^^^^ no rule.data', _rulesJson);
				}
				_sc.v = getFloat((_crL + _crR)/(1*6));
				//console.log('_CR', _sc.c2, 'L', _crL, 'R', _crR, _sc.v, _arrScRec);
			}

			_sc.v_r = _sc.v;
			if(_rule){
				_sc.v_r = getFloat(_sc.v * (_rule.v/25));
			}
			_scTotal += _sc.v_r;
			//console.log(_sc.c, _sc.v, _sc.v_r, _rule.v);
		});
		//console.log('overall check', _res);
		_rec.avgScoreP_r = (_scTotal / (10*4))*100;
		if(_res.scoreResultCT || (_res.score && _res.score.length>0 && _res.score[0].version == '4.00'))
			_rec.avgScoreP_r = (_scTotal / (10*6))*100;
		_rec.avgScoreP_r = Math.round(_rec.avgScoreP_r);
		//console.log('essayInfoGet', _rec, _scTotal);
		//console.log('essayInfoGet', _rec.scores, _scTotal);
		return _rec;
	},
	async essayScoreGet(token, profileId, essayId){
		return await AppHelper.apiPost('api/scores', {token:token, profileId:profileId, essayId:essayId});
	},
	async rulesJsonByProfile(token, grade, saMode=false){
		const _res = await AppHelper.apiPost('api/essay/score/rule/get', {token: token, grade:grade, isSA:saMode?1:0});
		if (_res && _res.status && _res.data){
			// if(grade=='P1') 
			// console.log('P1 >>>', _res);
			return EssayHelper.rulesJson(_res.data);
		}
		else return [];
	},
	async rulesJsonByProfileId(token, profileId, grade, saMode=false){
		const _res = await AppHelper.apiPost('api/essay/score/rule/getByProfileId', {token:token, profileId:profileId, grade:grade, isSA:saMode?1:0});
		if (_res && _res.status && _res.data){
				console.log('P1 >>>', _res);
			return EssayHelper.rulesJson(_res.data);
		}
		else return [];
	},
	rulesJson(recordsFromApi){
		var _records = [];
		recordsFromApi.forEach(r => {
			var _rec = {code:r.code, expand:false, data:[]};
			if(r.data && r.data.length>0){
				var _recOA = r.data.find(x=>x.title == 'overall');
				if(_recOA){
					_rec.v = _recOA.value;
					//console.log(_rec.code, _rec.v);
					//_rec.v0 = _rec.v;// * 4;
					_rec.title = r.code == 'GM' ? 'Grammar' 
						: r.code == 'SP' ? 'Spelling' 
						: r.code == 'VC' ? 'Vocabulary' 
						: r.code == 'ST' ? 'Structure & Flow'
						: r.code == 'CT' ? 'Development'
						: r.code == 'CR' ? 'Relevance & Logic'
						: '-';
					_records.push(_rec);

					const _textErrorPercent = '/10';//'/10 : Error % (Error Count / Total Word Count) must be below';
					if(r.code=='VC'){
						var _recVCA = r.data.find(x=>x.title == 'VCA');
						_rec.data.push({title:'Vocabulary Appropriateness', v:_recVCA? _recVCA.value:50, code:'VCA'});
						_rec.data[0].data = [];
						_rec.data[0].data.push( getRuleL2ByCode_1_10(r.data, 'VCA-ERR-%-10', 0, 'Score 10' + _textErrorPercent, true) );
						_rec.data[0].data.push( getRuleL2ByCode_1_10(r.data, 'VCA-ERR-%-9', 1, 'Score 9' + _textErrorPercent, true) );
						_rec.data[0].data.push( getRuleL2ByCode_1_10(r.data, 'VCA-ERR-%-8', 2, 'Score 8' + _textErrorPercent, true) );
						_rec.data[0].data.push( getRuleL2ByCode_1_10(r.data, 'VCA-ERR-%-7', 3, 'Score 7' + _textErrorPercent, true) );
						_rec.data[0].data.push( getRuleL2ByCode_1_10(r.data, 'VCA-ERR-%-6', 4, 'Score 6' + _textErrorPercent, true) );
						_rec.data[0].data.push( getRuleL2ByCode_1_10(r.data, 'VCA-ERR-%-5', 5, 'Score 5' + _textErrorPercent, true) );
						_rec.data[0].data.push( getRuleL2ByCode_1_10(r.data, 'VCA-ERR-%-4', 6, 'Score 4' + _textErrorPercent, true) );
						_rec.data[0].data.push( getRuleL2ByCode_1_10(r.data, 'VCA-ERR-%-3', 7, 'Score 3' + _textErrorPercent, true) );
						_rec.data[0].data.push( getRuleL2ByCode_1_10(r.data, 'VCA-ERR-%-2', 8, 'Score 2' + _textErrorPercent, true) );
						_rec.data[0].data.push( getRuleL2ByCode_1_10(r.data, 'VCA-ERR-%-1', 9, 'Score 1' + _textErrorPercent, true) );
						
						var _recVCX = r.data.find(x=>x.title == 'VCX');
						_rec.data.push({title:'Vocabulary Complexity', v:_recVCX? _recVCX.value:50, code:'VCX'});
						_rec.data[1].data = [];
						_rec.data[1].data.push( getRuleL2ByCode_1_10(r.data, 'VCX-ERR-%-10', 10, 'Score 10' + _textErrorPercent, true) );
						_rec.data[1].data.push( getRuleL2ByCode_1_10(r.data, 'VCX-ERR-%-9', 9, 'Score 9' + _textErrorPercent, true) );
						_rec.data[1].data.push( getRuleL2ByCode_1_10(r.data, 'VCX-ERR-%-8', 8, 'Score 8' + _textErrorPercent, true) );
						_rec.data[1].data.push( getRuleL2ByCode_1_10(r.data, 'VCX-ERR-%-7', 7, 'Score 7' + _textErrorPercent, true) );
						_rec.data[1].data.push( getRuleL2ByCode_1_10(r.data, 'VCX-ERR-%-6', 6, 'Score 6' + _textErrorPercent, true) );
						_rec.data[1].data.push( getRuleL2ByCode_1_10(r.data, 'VCX-ERR-%-5', 5, 'Score 5' + _textErrorPercent, true) );
						_rec.data[1].data.push( getRuleL2ByCode_1_10(r.data, 'VCX-ERR-%-4', 4, 'Score 4' + _textErrorPercent, true) );
						_rec.data[1].data.push( getRuleL2ByCode_1_10(r.data, 'VCX-ERR-%-3', 3, 'Score 3' + _textErrorPercent, true) );
						_rec.data[1].data.push( getRuleL2ByCode_1_10(r.data, 'VCX-ERR-%-2', 2, 'Score 2' + _textErrorPercent, true) );
						_rec.data[1].data.push( getRuleL2ByCode_1_10(r.data, 'VCX-ERR-%-1', 1, 'Score 1' + _textErrorPercent, true) );
					}
					else if(r.code=='ST'){
						_rec.data.push( getRuleL2ByCode(r.data, 'O', 25, 'Organisation') );
						addST_L3(_rec.data[0], 'O', r.data);
						_rec.data.push( getRuleL2ByCode(r.data, 'T', 25, 'Transitions') );
						addST_L3(_rec.data[1], 'T', r.data);
						_rec.data.push( getRuleL2ByCode(r.data, 'P', 25, 'Paragraphing') );
						addST_L3(_rec.data[2], 'P', r.data);
						_rec.data.push( getRuleL2ByCode(r.data, 'TR', 25, 'Title Relevance') );
						addST_L3(_rec.data[3], 'TR', r.data);
					}
					else if(r.code=='SP'){
						_rec.data.push( getRuleL2ByCode_1_10(r.data, 'SP-ERR-%-10', 0, 'Score 10' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode_1_10(r.data, 'SP-ERR-%-9', 1, 'Score 9' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode_1_10(r.data, 'SP-ERR-%-8', 2, 'Score 8' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode_1_10(r.data, 'SP-ERR-%-7', 3, 'Score 7' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode_1_10(r.data, 'SP-ERR-%-6', 4, 'Score 6' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode_1_10(r.data, 'SP-ERR-%-5', 5, 'Score 5' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode_1_10(r.data, 'SP-ERR-%-4', 6, 'Score 4' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode_1_10(r.data, 'SP-ERR-%-3', 7, 'Score 3' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode_1_10(r.data, 'SP-ERR-%-2', 8, 'Score 2' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode_1_10(r.data, 'SP-ERR-%-1', 9, 'Score 1' + _textErrorPercent, true) );
					}
					else if(r.code=='GM'){
						_rec.data.push( getRuleL2ByCode(r.data, 'GM-ERR-%-10', 0, 'Score 10' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode(r.data, 'GM-ERR-%-9', 1, 'Score 9' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode(r.data, 'GM-ERR-%-8', 2, 'Score 8' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode(r.data, 'GM-ERR-%-7', 3, 'Score 7' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode(r.data, 'GM-ERR-%-6', 4, 'Score 6' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode(r.data, 'GM-ERR-%-5', 5, 'Score 5' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode(r.data, 'GM-ERR-%-4', 6, 'Score 4' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode(r.data, 'GM-ERR-%-3', 8, 'Score 3' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode(r.data, 'GM-ERR-%-2', 8, 'Score 2' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode(r.data, 'GM-ERR-%-1', 9, 'Score 1' + _textErrorPercent, true) );
					}
					else if(r.code=='CT'){
						//_rec.data.push( getRuleL2ByCode(r.data, 'L', 25, 'Logic') );
						//addST_L3(_rec.data[0], 'L', r.data);
						_rec.data.push( getRuleL2ByCode(r.data, 'I', 33, 'Introduction') );
						addCT_L3(_rec.data[0], 'I', r.data);
						_rec.data.push( getRuleL2ByCode(r.data, 'B', 33, 'Body') );
						addCT_L3(_rec.data[1], 'B', r.data);
						_rec.data.push( getRuleL2ByCode(r.data, 'C', 33, 'Conclusion') );
						addCT_L3(_rec.data[2], 'C', r.data);
					}
					else if(r.code=='CR'){
						//console.log('>>> rule CT', r);
						_rec.data.push( getRuleL2ByCode(r.data, 'R', 50, 'Content Relevance') );
						addCT_L3(_rec.data[0], 'R', r.data);
						_rec.data.push( getRuleL2ByCode(r.data, 'L', 50, 'Logic') );
						addCT_L3(_rec.data[1], 'L', r.data);
					}
				}
			}
		});
		//console.log('rulesFromJson', _records);
		return _records;
	},
	scoreVersionNumFromScore(essayData){
		if(!essayData || !essayData.scores) return 1;
		const argScoreList = essayData.scores;
		if(!argScoreList || argScoreList.length<1) return 1;
		var _version = parseFloat(argScoreList[0].version);
		if(!_version == NaN) _version = 1;
		return _version;
	}
}

const getErrorPercentByScore = (argScore)=>{
	if(argScore == 10) return 0;
	if(argScore == 9) return 1;
	if(argScore == 8) return 2;
	if(argScore == 7) return 3;
	if(argScore == 6) return 4;
	if(argScore == 5) return 5;
	if(argScore == 4) return 6;
	if(argScore == 3) return 7;
	if(argScore == 2) return 8;
	if(argScore == 1) return 9;
}
const getScoreL2 = (argSubCode, _arrScRec, _rule, subTotal, defaultValue, baseV = 1)=>{
	var _scRec = _arrScRec.find(_rs=>_rs.subScoreCode == argSubCode);
	var _ruleRec = _rule.data.find(x=>x.code == argSubCode);
	// if(argSubCode=='I'){
	// 	console.log('I', _scRec, _ruleRec);
	// }
	if(_scRec && _ruleRec){
		return getFloat((_scRec.score*baseV) * (_ruleRec.v/subTotal));
	}
	if(_scRec){
		return getFloat(_scRec.score);
	}
	return defaultValue;
}
const getScore_byRulePercent = (argSubCode, _arrScRec, _rule, _scoreTotal, defaultValue)=>{
	var _scRec = _arrScRec.find(_rs=>_rs.subScoreCode == argSubCode);
	var _ruleRec = _rule.data.find(x=>x.code == argSubCode);
	if(_scRec && _ruleRec){
		//console.log(_scRec.score, _ruleRec.v, _scoreTotal, _ruleRec);
		return getFloat(((_scRec.score/_scoreTotal)*_ruleRec.v)/_scoreTotal);
		//return getFloat((_scRec.score*baseV) * (_ruleRec.v/subTotal));
	}
	if(_scRec){
		return getFloat(_scRec.score);
	}
	return defaultValue;
}
const getFloat = (arg)=>{
	return parseFloat(arg.toFixed(1));
}
const getRuleL2ByCode = (argData, argCode, defaultValue, argTitle, pop=false, ttip='')=>{
	var _rec = argData.find(x=>x.title == argCode);
	return {title:argTitle, v:_rec?_rec.value:defaultValue, code:argCode, pop:pop, ttip:ttip};
}
const getRuleL2ByCode_1_10 = (argData, argCode, defaultValue, argTitle, pop=false)=>{
	var _rec = argData.find(x=>x.title == argCode);
	var _v = (_rec?_rec.value:defaultValue);
	return {title:argTitle, v:_v, code:argCode, pop:pop};
}
const addST_L3 = (arg, argCode, argData)=>{
	if(!arg) return;
	arg.data = [];
	arg.data.push( getRuleL2ByCode(argData, `${argCode}-1`, 25, '1/4 (Needs Improvement)'));
	arg.data.push( getRuleL2ByCode(argData, `${argCode}-2`, 50, '2/4 (Developing) '));
	arg.data.push( getRuleL2ByCode(argData, `${argCode}-3`, 75, '3/4 (Competent)'));
	arg.data.push( getRuleL2ByCode(argData, `${argCode}-4`, 100, '4/4 (Proficient)'));
};
const addCT_L3 = (arg, argCode, argData)=>{
	if(!arg) return;
	arg.data = [];
	arg.data.push( getRuleL2ByCode(argData, `${argCode}-1`, 25, '1/6 (Needs Improvement)'));
	arg.data.push( getRuleL2ByCode(argData, `${argCode}-2`, 50, '2/6 (Emerging) '));
	arg.data.push( getRuleL2ByCode(argData, `${argCode}-3`, 75, '3/6 (Developing)'));
	arg.data.push( getRuleL2ByCode(argData, `${argCode}-4`, 100, '4/6 (Competent)'));
	arg.data.push( getRuleL2ByCode(argData, `${argCode}-5`, 100, '5/6 (Skilled)'));
	arg.data.push( getRuleL2ByCode(argData, `${argCode}-6`, 100, '6/6 (Proficient)'));
};
const titleAsReadable = (argTitle, argSplitter='_')=>{
	if(!argTitle) return '';
	var _arrSubTitle = argTitle.split(argSplitter);
	return _arrSubTitle.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
}
const wordCount = (_txt) =>{
	if(!_txt) return 0;
	return _txt.trim().split(/\s+/).length;
};