import React, { useEffect, forwardRef } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';

const ScanView = forwardRef(({ isOpen, onClose, pages, numPages }, ref) => {
  useEffect(() => {
    // Add any side effect code here
  }, [isOpen]);

  const handleClick = (e, imageUrl) => {
    e.preventDefault();
    const newWindow = window.open();
    newWindow.document.write(`
		  <style>
			body {
			  margin: 0;
			  display: flex;
			  justify-content: center;
			  align-items: center;			  
			  overflow-x: hidden; 
			}
			img {			 
			  max-width: 100%;
			  height: auto;
			  display: block;
			}
		  </style>
		  <img src="${imageUrl}" alt="Scanned Image"/>
		`);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Scan</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {[...Array(numPages)].map((_, index) => (
            <Grid
              item
              xs={4} // 3 images per row since 12 / 4 = 3
              key={index}
              style={{
                textAlign: 'center',
              }}
            >
              {pages[index] !== null && (
                <div>
                  <a href="#" onClick={(e) => handleClick(e, `data:image/jpeg;base64,${pages[index]}`)}>
                    <img
                      src={`data:image/jpeg;base64,${pages[index]}`}
                      alt={`Page ${index + 1}`}
                      style={{ height: '150px', width: '150px', marginBottom: '8px' }}
                    />
                  </a>
                  <br />
                  <span>{`Page ${index + 1}`}</span>
                </div>
              )}
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <Button onClick={onClose} style={{ position: 'absolute', top: 0, right: 0 }}>
        <CloseIcon />
      </Button>
    </Dialog>
  );
});

export default ScanView;
