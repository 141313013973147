import React, { useEffect, useState, useRef } from "react";
import { AppHelper } from '../../AppHelper';
import {
  Button, Box, Chip, Dialog, DialogTitle, Grid, IconButton,
  ListItem, ListItemIcon, ListItemText,
  Tooltip, Typography
} from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import GppGoodIcon from '@mui/icons-material/GppGood';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { pink, green } from '@mui/material/colors';
import PopErrorAdd from "./pop-error-add";
import PopScoreGrading from "./pop-score-grading";

export default function ScoreSubExpand({
  scoreCode, recordSc, records, essay, allowMark, onMarkDone
}) {
  const [subList, setSubList] = useState([]);
  const [popScoreGradingOpen, setPopScoreGradingOpen] = useState(false);

  useEffect(() => {
    if (records && records.length > 0) {
      records.forEach(r => {
        r.eg = [];
        if (r.exampleImprovements) {
          r.exampleImprovements.forEach((ei, i) => {
            r.eg.push({
              v: ei,
              isFalse: r.exampleImprovementsFalse && r.exampleImprovementsFalse[i] && r.exampleImprovementsFalse[i].isFalse == 1
            });
          });
        }
      });
      setSubList(records);
    }
    //console.log('records >>> ', recordSc);
  }, [scoreCode, records]);

  const onFalseClick = async (iSub, argIndex, argTitle, argValue) => {
    console.log('scoreCode', scoreCode);
    var _code = argTitle.toLowerCase().replace(/ /g, "_");
    var _user = AppHelper.userCacheGet();

    if (_code == "logic" && scoreCode == "CR") scoreCode = "CT";

    var _url = 'api/score/' + scoreCode + '/error/false';
    var _payload = { token: _user.token, essayId: essay.essayId, errorIndex: argIndex, isFalse: argValue, code: _code, profileId: _user.profileId };

    var _res = await AppHelper.apiPost(_url, _payload);
    if (!_res || !_res.status) {
      alert('ERROR: Failed to set false!');
      return;
    }
    var _tmpList = [...subList];
    var _sub = _tmpList[iSub];
    var _eg = _sub.eg[argIndex];
    _eg.isFalse = argValue == 1;
    setSubList(_tmpList);
  };
  const popInfoShow = () => {
    //setPopScoreGradingOpen(true);
  }
  const popScoreGradingView = (arg) => {
    //console.log(arg, argScore);
    setPopScoreGradingOpen(true);
  }
  const popScoreGradingClose = (arg) => {
    setPopScoreGradingOpen(false);
  }

  return (
    <>
      {subList.map((rSub, iSub) => (
        <Grid key={'score_sub_' + iSub} container>
          <Grid item xs={12} mt={1}>
            <Typography sx={{ fontWeight: 'bold', color: '#666', marginRight: '0', marginLeft: '1rem', display: "flex", alignItems: "center" }}>
              {rSub.score}/{scoreCode == 'ST' ? 4 : scoreCode == 'CT' ? 6 : scoreCode == 'CR' ? 6 : 4} {rSub.title}
              &nbsp;
              {scoreCode != 'CT' && scoreCode != 'CR' &&
                <>
                  (Student Level: {rSub.score == 4 ? 'Proficient' : rSub.score == 3 ? 'Competent' : rSub.score == 2 ? 'Developing' : 'Needs Improvement'})
                </>
              }
              {(scoreCode == 'CT' || scoreCode == 'CR') &&
                <>
                  (Student Level: {rSub.score == 6 ? 'Proficient'
                    : rSub.score == 5 ? 'Skilled' : rSub.score == 4 ? 'Competent'
                      : rSub.score == 3 ? 'Developing' : rSub.score == 2 ? 'Emerging' : 'Needs Improvement'})
                  <Tooltip
                    enterTouchDelay={0}
                    leaveTouchDelay={1500}
                    title={
                      scoreCode == 'CT' && rSub.title == 'Intro' ?
                        rSub.score == 6 ? 'Unique and engaging opening; fully sets the scene (Who, What, When, Where).'
                          : rSub.score == 5 ? 'Engaging but familiar opening; adequately sets the scene (Who, What, When, Where).'
                            : rSub.score == 4 ? 'Opening present, covers the basics (Who, What, When, Where) but lacks depth.'
                              : rSub.score == 3 ? 'No clear opening; addresses only three of the 4Ws (Who, What, When, Where).'
                                : rSub.score == 2 ? 'Lacks a clear start; addresses two of the 4Ws (Who, What, When, Where).'
                                  : rSub.score == 1 ? 'No opening; covers only one of the 4Ws (Who, What, When, Where).'
                                    : 'NA'
                        : scoreCode == 'CT' && rSub.title == 'Body' ?
                          rSub.score == 6 ? 'Plot is thoroughly developed with clear progression.'
                            : rSub.score == 5 ? 'Plot is adequately developed and structured.'
                              : rSub.score == 4 ? 'Plot development is minimal but present.'
                                : rSub.score == 3 ? 'Plot is underdeveloped, with noticeable gaps.'
                                  : rSub.score == 2 ? 'Plot is vague and confusing, with multiple missing parts.'
                                    : rSub.score == 1 ? 'Plot is disjointed and confusing, lacking coherence and direction.'
                                      : 'NA'
                          : scoreCode == 'CT' && rSub.title == 'Conclusion' ?
                            rSub.score == 6 ? 'Concludes meaningfully with reflections or future insights.'
                              : rSub.score == 5 ? 'Concludes well with either reflections or future insights.'
                                : rSub.score == 4 ? 'Ending is general and lacks specificity to the topic.'
                                  : rSub.score == 3 ? 'Abrupt ending with limited closure.'
                                    : rSub.score == 2 ? 'Abrupt and rushed, offering little closure.'
                                      : rSub.score == 1 ? 'Unrelated and confusing ending, without a clear conclusion.'
                                        : 'NA'
                            : scoreCode == 'CR' && rSub.title == 'Relevance' ?
                              rSub.score == 6 ? 'Entirely relevant with well-structured ideas; all points align closely with the topic.'
                                : rSub.score == 5 ? 'Strongly relevant, with minor unrelated points; the overall topic focus is maintained.'
                                  : rSub.score == 4 ? 'Generally relevant; some sections are less clear or detailed, but the main ideas relate to the topic.'
                                    : rSub.score == 3 ? 'Includes relevant ideas but also some off-topic content; overall focus is inconsistent.'
                                      : rSub.score == 2 ? 'Significant unrelated content; weak focus on the main topic with numerous irrelevant ideas.'
                                        : rSub.score == 1 ? 'Mostly off-topic with little meaningful connection to the main theme.'
                                          : 'NA'
                              : scoreCode == 'CR' && rSub.title == 'Relevance2' ?
                                rSub.score == 6 ? 'Entirely on-topic with all ideas clearly relevant; pictures are thoughtfully included and well-described.'
                                  : rSub.score == 5 ? 'Predominantly on-topic; minor digressions. Pictures are effectively used and described.'
                                    : rSub.score == 4 ? 'Mainly on-topic; some parts lack detail. Pictures are included but lack description.'
                                      : rSub.score == 3 ? 'Relevant ideas present but mixed with some off-topic content; pictures are minimally incorporated.'
                                        : rSub.score == 2 ? 'Large amount of irrelevant content; pictures rarely included or not utilized well.'
                                          : rSub.score == 1 ? 'Mostly unrelated to the topic; pictures are missing or ignored.'
                                            : 'NA'
                                : scoreCode == 'CR' && rSub.title == 'Logic' ?
                                  rSub.score == 6 ? 'Ideas flow seamlessly and logically, with clear descriptions.'
                                    : rSub.score == 5 ? 'Mostly logical and cohesive, with detailed explanations.'
                                      : rSub.score == 4 ? 'Largely logical, though some parts lack description.'
                                        : rSub.score == 3 ? 'Some inconsistencies or confusing points.'
                                          : rSub.score == 2 ? 'Multiple gaps in logic; descriptions are weak.'
                                            : rSub.score == 1 ? 'Disorganized and illogical flow throughout; descriptions are insufficient.'
                                              : 'NA'
                                  : 'NA'
                    }
                    placement="bottom">
                    <InfoIcon sx={{ marginLeft: '.3rem' }} />
                  </Tooltip>
                  {/* <IconButton onClick={() => popScoreGradingView()}>
                <InfoIcon />
              </IconButton> */}
                </>
              }
              {/* <Chip variant="outlined" sx={{marginLeft:'0.5rem'}} 
              icon={rSub.score == 4? <GppGoodIcon />: rSub.score == 3? <LightbulbIcon />: rSub.score == 2? <ErrorIcon />:<ErrorIcon />} 
              label={rSub.score == 4? 'Proficient': rSub.score == 3? 'Competent': rSub.score == 2? 'Developing':'Needs Improvement'}
            /> */}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {rSub.eg.map((sEg, iSEg) => (
              <Grid key={'sug_iSub_' + iSEg} container>
                <Grid item xs={12}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ flexGrow: 1 }}>
                      <ListItem
                      // secondaryAction={
                      //   <div>
                      //   { allowMark && sEg.isFalse &&
                      //     <Tooltip title="Mark as Positive">
                      //       <IconButton onClick={()=>onFalseClick(iSub, iSEg, rSub.title, 0)} edge="end" aria-label="delete">
                      //         <TaskAltIcon style={{color:green[500]}} />
                      //       </IconButton>
                      //     </Tooltip>
                      //   }
                      //   { allowMark && !sEg.isFalse &&
                      //     <Tooltip title="Mark as False Positive">
                      //       <IconButton onClick={()=>onFalseClick(iSub, iSEg, rSub.title, 1)} edge="end" aria-label="delete">
                      //         <RemoveCircleOutlineIcon style={{color:pink[500]}} />
                      //       </IconButton>
                      //     </Tooltip>
                      //   }
                      //   </div>
                      // }
                      >
                        <ListItemIcon sx={{ minWidth: '40px' }}>
                          <TipsAndUpdatesIcon sx={{ color: 'rgb(157, 188, 152)' }} />
                        </ListItemIcon>
                        <ListItemText sx={{ textDecoration: (sEg.isFalse == 1) ? 'line-through' : '' }}
                          primary={sEg.v}
                        //secondary={'2nd row'}
                        />
                      </ListItem>
                    </div>
                    <div>
                      <div style={{ paddingRight: '115px' }}>
                        {allowMark && sEg.isFalse &&
                          <Tooltip title="Mark as Positive">
                            <IconButton onClick={() => onFalseClick(iSub, iSEg, rSub.title, 0)} edge="end" aria-label="delete">
                              <TaskAltIcon style={{ color: green[500] }} />
                            </IconButton>
                          </Tooltip>
                        }
                        {allowMark && !sEg.isFalse &&
                          <Tooltip title="Mark as False Positive">
                            <IconButton onClick={() => onFalseClick(iSub, iSEg, rSub.title, 1)} edge="end" aria-label="delete">
                              <RemoveCircleOutlineIcon style={{ color: pink[500] }} />
                            </IconButton>
                          </Tooltip>
                        }
                      </div>
                    </div>
                  </div>
                  {/* <Box>
                  <TipsAndUpdatesIcon sx={{color:'rgb(157, 188, 152)'}} />
                  {sEg}
                </Box> */}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}

      <PopScoreGrading
        open={popScoreGradingOpen} onCancel={popScoreGradingClose}
      />
    </>
  );
}