import React, { useEffect, useState } from "react";
import { AppHelper } from '../../AppHelper';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EmailIcon from '@mui/icons-material/Email';
import { pink, green, blue } from '@mui/material/colors';
import {
  Button, Box, Dialog, DialogTitle, IconButton,
  Paper, CircularProgress, Table, TableHead, TableRow, TableCell, Tooltip
} from '@mui/material';
import dayjs from "dayjs";
import PopShareEmail from './pop-share-email';
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export default function PopScoreGrading ({
  onCancel, open, onStart
}){
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [oUser, setOUser] = useState(AppHelper.userCacheGet());
  const [popShareEmailOpen, setPopShareEmailOpen] = useState(false);
  const [shareId, setShareId] = useState('');
  
  useEffect(()=>{
    //console.log('popScanAgain', open);
    if(!open) return;
    setIsLoading(false);
  }, [open]);
  const handleClose = () =>{ onCancel() };
  const handleStart = () =>{ 
    onStart();
    setIsLoading(true);
  };
  return(
    <>
    <Dialog onClose={handleClose} open={open} sx={{minWidth:'400px'}}>
      <DialogTitle>Score Grading</DialogTitle>
      <Box padding={'0.5rem'} textAlign={'left'} paddingLeft={'2rem'} paddingRight={'2rem'} color={'000'}>
      <div>Score 1/6:  Needs Improvement</div>
      <div>Score 2/6: Emerging</div>
      <div>Score 3/6: Developing</div>
      <div>Score 4/6: Competent</div>
      <div>Score 5/6: Skilled</div>
      <div>Score 6/6: Proficient</div>
      </Box>
      { !isLoading &&
      <Box padding={'0.5rem'} textAlign={'center'}>
        {/* <Button onClick={handleStart} className="app-btn-bg" color="info" variant="contained" size="large" style={{marginRight:'2px'}}>Proceed</Button> */}
        <Button onClick={handleClose} color="info" variant="text" size="large" style={{marginRight:'2px'}}>Close</Button>
      </Box>
      }
      { isLoading &&
        <div style={{margin:'1rem', textAlign:'center'}}>
          <CircularProgress />
        </div>
      }
    </Dialog>
    </>
  );
}